import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { array, string } from 'prop-types'
import { rgba } from 'polished'

import Heading from 'components/Heading'
import { SectionBlock } from 'components/Section'

import * as spacing from 'styles/spacing'
import { media } from 'styles/media'
import { white } from 'styles/colors'
import { fontWeights } from 'styles/typography'

const Container = styled.div`
  counter-reset: items;
`

const StyledHeading = styled(Heading)`
  color: ${white};
`

const List = styled.div`
  display: block;
  color: ${white};
`

const Item = styled.div`
  display: flex;
  align-items: flex-start;

  &:before {
    content: '0' counter(items);
    counter-increment: items;
    color: ${rgba(white, 0.25)};
    margin-bottom: 0;
    font-weight: ${fontWeights.bold};
    font-size: 32px;
    line-height: 1;
    width: 40px;
    flex-shrink: 0;
    margin-right: ${spacing.medium};

    ${media.small`
      margin-right: ${spacing.large};
      width: 50px;
      font-size: 40px;
    `};

    ${media.medium`
      font-size: 72px;
      width: 96px;
      margin-right: ${spacing.xLarge};
    `};

    ${media.large`
      font-size: 84px;
      width: 110px;
    `};

    ${media.xLarge`
      font-size: 100px;
      width: 130px;
    `};
  }

  ${media.medium`
    align-items: center;
  `};

  & + & {
    margin-top: ${spacing.large};

    ${media.medium`
      margin-top: ${spacing.xLarge};
    `};

    ${media.large`
      margin-top: ${spacing.xxLarge};
    `};
  }
`

const ChangesList = ({ name, changes }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <SectionBlock>
        <StyledHeading size={2}>{t('changes.intro', { name })}</StyledHeading>
      </SectionBlock>
      <SectionBlock>
        <List>
          {changes.map((change, i) => (
            <Item key={i}>{change}</Item>
          ))}
        </List>
      </SectionBlock>
    </Container>
  )
}

ChangesList.propTypes = {
  name: string.isRequired,
  items: array.isRequired,
  date: string.isRequired,
}

export default ChangesList

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { white, teal } from 'styles/colors'
import { media, mediaDown } from 'styles/media'
import { cover } from 'styles/helpers'
import { Lead, fontWeights, fontSizes } from 'styles/typography'
import * as spacing from 'styles/spacing'

import Wrapper from 'components/Wrapper'
import Announcement from './Announcement'

const Container = styled.header`
  position: relative;
  background-color: ${teal};
  text-align: center;
  padding-top: 5rem;

  ${media.medium`
    padding-top: 7rem;
  `};

  ${media.large`
    padding-top: 8rem;
  `};
`

const Inner = styled.div`
  padding-top: 2rem;
  padding-bottom: 6rem;

  ${media.small`
    padding-top: 3rem;
    padding-bottom: 8rem;
  `};

  ${media.medium`
    padding-top: 4rem;
    padding-bottom: 10rem;
  `};

  ${media.large`
    padding-top: 5rem;
    padding-bottom: 12rem;
  `};

  ${media.xLarge`
    padding-top: 6rem;
    padding-bottom: 14rem;
  `};
`

const Content = styled.div`
  position: relative;
  z-index: 10;
`

export const Heading = styled(Lead)`
  color: ${white};
  text-transform: uppercase;
  font-weight: ${fontWeights.regular};
  margin-bottom: 2em;
  letter-spacing: 0.1em;

  ${media.medium`
    margin-bottom: 3em;
  `};

  &:after {
    content: '';
    display: block;
    width: 48px;
    height: 1px;
    background-color: ${white};
    margin-left: auto;
    margin-right: auto;
    margin-top: ${spacing.medium};

    ${media.small`
      width: 60px;
    `};

    ${media.medium`
      width: 80px;
      margin-top: ${spacing.large};
    `};

    ${media.large`
      width: 90px;
    `};

    ${media.xLarge`
      width: 108px;
    `};
  }
`

const Subheading = styled(Lead)`
  color: ${white};
  font-weight: ${fontWeights.bold};
  margin-left: auto;
  margin-right: auto;
  max-width: 920px;

  ${mediaDown.small`
    font-size: ${fontSizes.regular};
  `};

  ${media.xLarge`
    max-width: none;
  `};
`

const Background = styled.img`
  ${cover('absolute')};
  object-fit: cover;
`

const Hero = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Wrapper>
        <Announcement />
      </Wrapper>
      <Inner>
        <Wrapper>
          <Content>
            <Heading>{t('home.hero.title')}</Heading>
            <Subheading>{t('home.hero.subtitle')}</Subheading>
          </Content>
        </Wrapper>
        <Background src={require(`assets/images/header-shape.png`)} alt="" />
      </Inner>
    </Container>
  )
}

export default Hero

import React from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { rgba, mix } from 'polished'

import { white, black, teal } from 'styles/colors'
import { media, mediaDown } from 'styles/media'
import * as spacing from 'styles/spacing'

import Heading from 'components/Heading'

const Container = styled.div`
  position: relative;
  color: ${white};
  background-color: ${rgba(mix(0.3, black, teal), 0.75)};
  z-index: 10;
  text-align: left;
  padding: ${spacing.large};

  ${media.small`
    padding: ${spacing.xLarge};
  `};

  ${mediaDown.small`
    margin-left: -${spacing.large};
    margin-right: -${spacing.large};
  `};

  ${media.medium`
    padding: ${spacing.xxLarge};
  `};
`

const StyledHeading = styled(Heading)`
  color: ${white};
  text-transform: uppercase;
  margin-bottom: 1.25em;
`

const List = styled.ul`
  margin: 0;
  padding-left: 1.5rem;

  li + li {
    margin-top: 0.5rem;
  }
`

const Announcement = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <StyledHeading size={4}>{t('home.announcement.heading')}</StyledHeading>
      <Trans t={t} i18nKey="home.announcement.intro">
        <p>Paragraph 1</p>
        <p>Paragraph 2</p>
      </Trans>
      <List>
        <Trans t={t} i18nKey="home.announcement.description">
          <li>Paragraph 1</li>
          <li>Paragraph 2</li>
          <li>Paragraph 3</li>
          <li>Paragraph 4</li>
        </Trans>
      </List>
    </Container>
  )
}

export default Announcement

import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'

import { teal } from 'styles/colors'
import { media } from 'styles/media'
import { cover } from 'styles/helpers'

import Wrapper from 'components/Wrapper'
import { Heading } from 'components/home/Hero'

const Container = styled.header`
  position: relative;
  background-color: ${teal};
  text-align: center;
  padding-top: 7rem;
  padding-bottom: 3rem;

  ${media.small`
    padding-top: 9rem;
    padding-bottom: 5rem;
  `};

  ${media.medium`
    padding-top: 12rem;
    padding-bottom: 6rem;
  `};

  ${media.large`
    padding-top: 14rem;
    padding-bottom: 8rem;
  `};

  ${media.xLarge`
    padding-top: 16rem;
    padding-bottom: 9rem;
  `};
`

const Content = styled.div`
  position: relative;
  z-index: 10;
`

const StyledHeading = styled(Heading)`
  position: relative;
  z-index: 10;
`

const Background = styled.img`
  ${cover('absolute')};
  object-fit: cover;
`

const ShapeWrap = styled.div`
  position: relative;
  margin-top: -100px;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;

  &:after {
    content: '';
    display: block;
    padding-bottom: 59.25%;
  }

  ${media.medium`
    margin-top: -150px;
  `};

  ${media.large`
    margin-top: -200px;
  `};

  ${media.xLarge`
    margin-top: -230px;
  `};
`

const Shape = styled.img`
  object-fit: cover;
  ${cover('absolute')};
`

const ProvinceHeader = ({ name, slug }) => (
  <Container>
    <Wrapper>
      <Content>
        <StyledHeading>{name}</StyledHeading>
        <ShapeWrap>
          <Shape src={require(`assets/images/provinces/${slug}.png`)} />
        </ShapeWrap>
      </Content>
    </Wrapper>
    <Background src={require(`assets/images/header-shape.png`)} />
  </Container>
)

ProvinceHeader.propTypes = {
  name: string.isRequired,
  slug: string.isRequired,
}

export default ProvinceHeader

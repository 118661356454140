import React from 'react'
import Helmet from 'react-helmet'
import { string } from 'prop-types'

import ShareImage from 'assets/images/share-image.png'

const MetaTags = ({ title }) => (
  <Helmet>
    {/* Title + description */}
    <title>{title}</title>
    <meta
      name="description"
      content="The vaping regulatory landscape across Canada continues to evolve as governments and regulators consider and announce new laws that govern the vaping category. It can be difficult to keep track of these new developments and understand how new regulations impact the products that are available and where they can be purchased."
    />

    {/* Facebook */}
    <meta property="og:title" content={title} />
    <meta
      property="og:description"
      content="The vaping regulatory landscape across Canada continues to evolve as governments and regulators consider and announce new laws that govern the vaping category. It can be difficult to keep track of these new developments and understand how new regulations impact the products that are available and where they can be purchased."
    />
    <meta property="og:type" content="website" key="og:type" />

    <meta property="og:url" content="https://myvaperegs.ca" key="og:url" />
    <meta property="og:image" content={ShareImage} key="og:image" />

    {/* Twitter */}
    <meta
      name="twitter:card"
      content="summary_large_image"
      key="twitter:card"
    />
    <meta
      name="twitter:url"
      content="https://myvaperegs.ca"
      key="twitter:url"
    />
    <meta name="twitter:title" content={title} />
    <meta
      name="twitter:description"
      content="The vaping regulatory landscape across Canada continues to evolve as governments and regulators consider and announce new laws that govern the vaping category. It can be difficult to keep track of these new developments and understand how new regulations impact the products that are available and where they can be purchased."
    />
    <meta name="twitter:image" content={ShareImage} key="twitter:image" />
  </Helmet>
)

MetaTags.propTypes = {
  title: string.isRequired,
}

export default MetaTags
